
body {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    font-style: normal;
    color: #707070;
}

#theme-menu-one {
    border-bottom: 2px solid rgba(255, 114, 58, 0.1);
}
.my_video_peer_connection video {
    position: relative!important;
}
.main-header-area {
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
}
.main_scroll_body_component {
    overflow: auto;
    position: relative;
}
.main-header-area .logo img {
    width: 83px;
}
.main-header-area.sticky {
    padding-top: 0;
    width: calc(100vw - 16px)!important;
    padding-bottom: 0;
}
.main-header-area .main-menu {
    margin-left: 130px;
}
.main-header-area .navbar-nav .nav-item .nav-link {
    color: #505050;
    font-size: 2.3vh;
    line-height: 1;
    display: inline-block;
    position: relative;
    margin: 0 2.3vh;
    padding: 30px 0;
}
.main-header-area .free_btn {
    padding: 17px 38px;
}
.main-header-area .sign-in {
    background: rgba(40, 120, 235, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 44px;
    display: inline-block;
}
.main-header-area .navbar-nav .nav-item .nav-link:hover {
    color: #2878EB;
}
.theme_btn:hover {
    color: #ffffff;
    background: #e75115;
}
.slider__img__box {
    position: relative;
    z-index: 1;
}
.sl-shape.shape_01 {
    left: 8.54%;
    top: 210px;
    opacity: 0.3;
    -webkit-animation: float-bob-y infinite 5s linear;
    animation: float-bob-y infinite 5s linear;
}
.sl-shape.shape_02 {
    right: 47.5%;
    top: 170px;
    -webkit-animation: float-bob-y infinite 3s linear;
    animation: float-bob-y infinite 3s linear;
}
.sl-shape.shape_03 {
    right: 9.21%;
    top: 209px;
    -webkit-animation: float-bob-y infinite 4s linear;
    animation: float-bob-y infinite 4s linear;
}
.sl-shape.shape_04 {
    left: 1%;
    top: 607px;
    -webkit-animation: float-bob-y infinite 5s linear;
    animation: float-bob-y infinite 5s linear;
}
.sl-shape.shape_05 {
    right: 20%;
    top: 90%;
    opacity: 0.3;
}
.sl-shape.shape_06 {
    right: 24px;
    top: 562px;
    opacity: 0.3;
}
.sl-shape.shape_07 {
    left: 20.30%;
    top: 10px;
    -webkit-animation: float-bob-x infinite 5s linear;
    animation: float-bob-x infinite 5s linear;
}
.sl-shape {
    position: absolute;
    z-index: 1;
}
.slider__img__box .slide-shape.img-two {
    right: 68px;
    bottom: -26px;
    -webkit-animation: float-bob-y infinite 4s alternate;
    animation: float-bob-y infinite 4s alternate;
}
.slider__img__box .slide-shape.img-three {
    left: -108px;
    top: 53%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: float-bob-y infinite 6s alternate;
    animation: float-bob-y infinite 6s alternate;
}
.slider__img__box .slide-shape.img-four {
    left: 0;
    bottom: -66px;
}
.slider__img__box .slide-shape.img-five {
    right: 70px;
    top: 26px;
}
.slider__img__box .slide-shape {
    position: absolute;
    z-index: 1;
}
.slider__img__box .slide-shape.img-seven {
    position: absolute;
    z-index: 1;
    bottom: -113px;
    right: -228px;
}
.slider__img__box .slide-shape.img-eight {
    left: -29px;
    z-index: -1;
    bottom: -33px;
    -webkit-animation: rotation infinite 55s linear;
    animation: rotation infinite 55s linear;
}
.slider__img__box .slide-shape {
    position: absolute;
    z-index: 1;
}
.slider__content {
    position: relative;
    z-index: 8;
}
.main-title {
    color: #1B212F;
    font-size: 56px;
    line-height: 1.5;
    font-weight: 600;
}
.vec-shape {
    display: inline-block;
    position: relative;
    z-index: 1;
}
.slider__content h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.7;
}
.slider__content .highlight-text {
    color: #1B212F;
    display: inline-block;
}
.slider__content .highlight-text span {
    color: #f7931e;
}
.search__area {
    width: 100%;
    border-radius: 40px;
}
.search__area li .search_btn {
    -webkit-transform: translateX(7px);
    transform: translateX(0px);
}
.search_btn {
    border: 0;
    padding: 24px 41px;
    width: 100%;
}
.search__area li{
    width: 100%;
}
.img-bg::before {
    content: '';
    position: absolute;
    width: 572px;
    height: 479px;
    background-color: #f7931e;
    border-radius: 40px;
    left: 63px;
    top: auto;
    bottom: 0;
    z-index: -1;
}
.chose-img-wrapper .chose.chose_06 {
    bottom: -70px;
    right: 40px;
    z-index: -2;
    -webkit-animation: float-bob-y infinite 5s linear;
    animation: float-bob-y infinite 5s linear;
}
.chose-img-wrapper .chose {
    position: absolute;
}

.text-list li {
    color: #1B212F;
    font-weight: 500;
    padding-left: 35px;
    margin-bottom: 23px;
    position: relative;
    z-index: 1;
}
.text-list li:nth-child(2)::before {
    background-color: #f7931e;
}
.text-list li:last-child::before {
    background-color: #F14D5D;
}
.text-list li::before {
    content: '';
    font-family: 'Font Awesome 5 pro';
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #8E66FF;
    display: inline-block;
    border-radius: 50%;
    z-index: 1;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 25px;
}

.footer-area {
    position: relative;
    z-index: 1;
}
.logo img {
    max-width: 100%;
}
.footer__social a {
    background-color: rgba(255, 114, 58, 0.1);
    width: 39px;
    height: 39px;
    font-size: 16px;
    line-height: 39px;
}
.social-media a {
    color: #f7931e;
    font-size: 19px;
    background-color: #ffffff;
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 0 5px;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
}
.footer__widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}
.fot-list a {
    color: #707070;
    display: inline-block;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.copy-right-area .copyright h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
}
.copy-right-area .copyright h5 a {
    font-weight: 700;
}
.fot-list a:hover {
    color: #f7931e;
}
div#main_signup_component {
    position: fixed;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #ffffffb0;
}
div#main_signup_component {
    position: fixed;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #000000ba;
}
.main_signup_component_body {
    margin: auto;
    background: #fff;
    width: calc(100vh - 200px);
    border-radius: 9px;
    border: 1px solid #ccc;
}

.comments-form input {
    color: #707070;
    font-size: 2.3vh;
    height: 7vh;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    padding-left: 2vw;
    display: inline-block;
}
.comments-form input::placeholder{
    font-size: 2.1vh;
}
.contact-form textarea {
    font-weight: 600;
}
span.sapater_otp {
    margin: 0px 3vh;
}
.otp_container > div div {
    margin-left: auto;
    margin-right: auto;
}
.otp_container > div {
    display: flex;
    text-align: center;
    width: 100%;
}
.main_signup_component_body_inner form.row.gx-3.comments-form.contact-form {
    text-align: center;
}
.comments-form textarea {
    color: #707070;
    font-size: 16px;
    height: 250px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    padding-top: 28px;
    padding-left: 30px;
    display: inline-block;
    resize: none;
}
.otp_container {
    margin: auto;
}
.all_student_subject_main_container.teacher .demo_classes_main_section{
    width: 100%!important;
}
.contact-form-wrapper .message_btn {
    border: 0;
    font-size: 2.3vh;
    font-weight: 700;
    text-transform: uppercase;
    padding: 16px 4px;
    border-radius: 5px;
    margin-top: 17px;
}
.main_signup_component_body_inner {
    padding: 23px;
}
.otp_input_main {
    padding: unset!important;
    width: 37px!important;
}
.left_side_bar_main_section_container {
    height: 100vh;
}

.left_side_bar_main_section_container .left_side_bar_logo_section {
    display: flex;
}

.left_side_bar_main_section_container .left_side_bar_logo_section .logo_main_div {
    text-align: center;
    margin-top: 70px;
    font-size: 2.1vh;
    line-height: 42px;
    font-weight: 600;
    color: #1a2435;
    width: 100%;
}

.left_side_bar_main_section_container .left_side_bar_logo_section .logo_main_div img {
    width: 37%;
}

.left_side_content_menu_section {
    width: 100%;
    margin-top: 4vh;
}

.left_side_content_menu_section .menu_loop_section {
    display: flex;
    margin-top: 2vh;
}
.student_dash_header_inner_section .main_heading_icon {
    width: 5vh;
}
.left_side_content_menu_section .menu_loop_section .menu_loop_section_inner_section svg {
    width: 14%;
    fill: #979797;
}

.menu_loop_section .menu_loop_section_inner_section {
    width: 100%;
    display: flex;
}
.header_main_page .clock_section img {
    width: 48px;
}
.menu_loop_section .menu_loop_section_inner_section .left_menu_item {
    margin: auto;
    text-align: center;
}

.menu_loop_section .menu_loop_section_inner_section .left_menu_item .left_menu_item_2 {
    font-size: 2vh;
    line-height: 38px;
    color: #979797;
}
.header_main_page .logo_section {
    text-align: center;
}

.header_main_page .logo_section img {
    width: 48px;
}

.header_main_page {
    padding: 10px 7px;
    border-bottom: 1px solid #ddd;
}
.main_container_app_section .name_tite_section {
    text-align: center;
    padding: 31px;
    font-size: 21px;
    font-weight: 700;
    font-family: monospace;
}

.main_container_app_section .name_tite_section div {
    font-size: 14px;
    font-weight: 100;
    margin-top: 9px;
    font-family: unset;
}

.main_container_app_section .name_tite_section {
    text-align: center;
    padding: 31px;
    font-size: 21px;
    font-weight: 700;
    font-family: monospace;
}

.main_container_app_section .name_tite_section div {
    font-size: 14px;
    font-weight: 100;
    margin-top: 9px;
    font-family: unset;
}

.main_subject_section_today_classes {
    padding: 20px;
}

.main_subject_section_today_classes h3 {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
}

.header_main_page .logout_section {
    text-align: right;
    display: flex;
}

.header_main_page .logout_section img {
    width: 37px;
    margin-left: auto;
}

.header_main_page .clock_section img {
    width: 48px;
}

.header_main_page .logo_section {
    display: flex;
}

.header_main_page .logo_section h2 {
    font-size: 18px;
    margin: auto;
    color: #3c3c3c;
}
.menu_loop_section_inner_section.active svg {
    fill: #f7931e!important;
}

.menu_loop_section_inner_section.active .left_menu_item_2 {
    color: #000!important;
}
.main_body_content_section {
    box-shadow: 1px 0px 3px 0px #afafaf;
    background-image: linear-gradient(180deg, #ffb967, #fff0df);
    height: 100vh;
    padding: 40px 17px;
    overflow: auto;
}

.student_dash_header_inner_section {
    display: flex;
}

.user_create_profile_main_row .personal_prof_form {
    background: #ffffff5c;
    height: calc(100vh - 43vh);
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    margin: 0px 6px;
    padding: 29px;
}

.user_create_profile_main_row .personal_prof_form_2 {
    height: calc(100vh - 43vh);
    background: #c093ff;
    border-radius: 0px;
    overflow: auto;
    width: 100%;
    margin: 0px 6px;
    padding: 2.3vh;
}
.user_create_profile_main_row .personal_prof_form3 {
    height: calc(100vh - 43vh);
    background: #ffffff5c;
    border-radius: 0px;
    overflow: auto;
    width: 100%;
    margin: 0px 6px;
    padding: 2.3vh;
}
.user_create_profile_main_row .profile_form_header {
    color: #fff;
    font-size: 26px;
    text-align: center;
    width: 100%;
}
.student_class_second_col{
    position: relative;
}
.user_create_profile_main_row .personal_prof_form img {
    margin: auto;
}

.student_dash_header_inner_section .main_heading {
    font-size: 3.4vh;
    color: #1a2435;
    line-height: 44px;
}

.student_dash_header_inner_section .main_heading span {
    font-weight: 600;
}

.student_dash_header_section {
    padding-top: 60px;
}
.multiple_select_input .search-wrapper.searchWrapper {
    background: #fff!important;
    font-size: 15px!important;
}
.student_dash_header_inner_section .main_heading_icon {
    width: 7vh;
    margin-left: 30px;
    margin-top: 14px;
}

.demo_classes_main_section {
    background: #ffffff8c;
    border-radius: 8px;
    padding: 18px 0px 18px 7px;
    box-shadow: 0px 0px 20px 3px #ff5e0038;
}

.student_dash_header_section .sub_heading {
    line-height: 2.5vh;
    font-size: 2.4vh;
}

.student_demo_classes_main_page h2{
    font-size: 2.5vh;
}
.class_star_rating_component {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background:#ffffffd6;
    display: flex;
    z-index: 99999999;
}

.class_star_rating_component .class_star_rating_component_inner {
    margin: auto;
    padding: 43px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 20px 0px #cccccc52;
    line-height: 58px;
}

.class_star_rating_component .class_star_rating_component_inner button.theme_btn.submit_star_rating_button {
    width: 100%;
}

.class_star_rating_component .class_star_rating_component_inner p.start_message {
    font-size: 16px;
}

.class_star_rating_component .class_star_rating_component_inner .star_rating_main_div_with_stars i.active {
    color: #f7931e;
}

.class_star_rating_component .class_star_rating_component_inner .star_rating_main_div_with_stars i {
    cursor: pointer;
}

.star_rating_main_div_with_stars {
    font-size: 32px;
}
.profile_form_main_inner_body .form-group label {
    color: #fff;
    font-size: 16px!important;
}
.call_ent_button_section button.tap_to_speak_button {
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    background: #838383;
}

.call_ent_button_section button.tap_to_speak_button.tap {
    background: #4a00ff;
}
.side_other_video_with_details .member_name_section {
    text-align: center;
    font-size: 2.3vh;
    color: #fff;
}
.fc-toolbar-chunk .fc .fc-button{
    opacity: 0!important;
}
.fc .fc-toolbar-title{
    font-size: 2.8vh!important;
}
.fc .fc-col-header-cell-cushion {
    font-size: 2.1vh;
}
.fc .fc-button .fc-icon{
    font-size: 2.8vh!important;
}
.fc .fc-daygrid-day-number {
    font-size: 2vh;
}
.fc .fc-button{
    padding: 0.1em 0.65em;
}
.profile_form_main_inner_body .form-group  input,.profile_form_main_inner_body .form-group  select {
    font-size: 15px;
    border-radius: 5px;
    text-overflow: ellipsis;
    color:#7a7a7a;
}
.profile_form_main_inner_body .form-group  input:focus {
    box-shadow: none!important;
    border-color: #f7931e!important;
    outline: #f7931e!important;
}

.profile_form_main_inner_body2 .form-group  input,.profile_form_main_inner_body2 .form-group  select {
    font-size: 15px;
    border-radius: 5px;
    text-overflow: ellipsis;
    color:#7a7a7a;
}
.profile_form_main_inner_body2 .form-group  input:focus,.profile_form_main_inner_body2 .form-group  select:focus {
    box-shadow: none!important;
    border-color: #f7931e!important;
    outline: #f7931e!important;
}

.no_demo_classes_div_section {
    text-align: center;
    font-size: 21px;
    line-height: 53px;
    color: #ff500a;
}
.class_list_table .icon_setion {
    width: 52px;
    border-radius: 7px;
    padding: 10px;
    height: 52px;
    margin-top: auto;
    display: flex;
    margin-bottom: auto;
}
.class_list_table .icon_setion svg {
    min-width: 30px;
    margin: auto;
}
.class_data_main_table_section .icon_main_col {
    display: flex;
    font-size: 16px;
}

.icon_main_col .name_section {margin: 20px 20px;}

.class_list_table.header_row {
    border-bottom: 1px solid #ccc;
    line-height: 48px;
    color: #f7931e;
    font-weight: 600;
    font-size: 2vh;
}
.icon_main_col .name_section {
    font-size: 2.2vh;
    margin-top: auto;
    margin-bottom: auto;
}
.class_data_main_table_section .body {
    font-size: 2vh;
    margin-top: auto;
    margin-bottom: auto;
}
.class_data_main_table_section {
    background: #fff;
    border-radius: 10px;
    padding: 14px;
}

.demo_classes_main_section_div {
    display: flex;
    flex-wrap: wrap;
}

.demo_classes_main_section_div .demo_classes_section_loop {
    background: #fff;
    border-radius: 18px;
    padding: 15px;
    overflow: hidden;
    margin: 10px 10px;
    width: 46%;
}
div#teacher_video_class_container {
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background: #686868;
    position: fixed;
    z-index: 9999999;
}

.demo_classes_section_subject_icon_name {
    display: flex;
}
.main_body_content_section.all_student_subject_main_container.with_student {
    width: 70%;
}
.canvas_next_prev_button_container .next_prev_button.page_number button {
    border-radius: 50%!important;
    background: #6a6a6a!important;
    width: 30px;
    height: 30px;
    padding: 0px!important;
}
.demo_classes_section_subject_icon_name .icon_sub {
    height: 50px;
    width: 63px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    text-align: center;
    margin: 0px 10px;
}
.canvas_next_prev_button_container {
    width: 100%;
    text-align: center;
    display: flex;
    height: 40px;
}

.canvas_next_prev_button_container .next_prev_button {
    margin: auto;
    font-size: 12px;
    font-weight: 900;
}

.canvas_next_prev_button_container .next_prev_button button {
    background: #ff8e1f;
    padding: 10px 42px;
    color: #fff;
    font-weight: 600;
    border-radius: 14px;
}
.payment_popup_main_container {
    position: fixed;
    z-index: 9999999;
    top: 0px;
    left: 0px;
    background: #fffffffc;
    display: flex;
    width: 100%;
    height: 100vh;
}
.success_add_div {
    background: #2db500;
    height: 44px;
    border-radius: 11px;
    padding: 10px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}
.datatable_container_main_div_section {
    margin-top: 40px;
    width: 80%;
    margin-right: 0px;
}
.assign_create_section button {
    width: 100%;
}
.assign_class_main_popup_outer_container {
    position: fixed;
    background: #ffffffeb;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
}
.form-floating.class_date_time_sectiob_loop {
    display: flex;
}

.form-floating.class_date_time_sectiob_loop input {
    padding: 6px;
    width: 99px;
    margin-left: 10px;
    height: 27px;
    border-radius: 6px;
}
.assign_class_main_popup_outer_container.video_player_main_section {
    z-index: 9999999!important;
}
.assign_class_main_popup_outer_container.video_player_main_section .assign_class_main_popup_inner_container {
    width: 72%!important;
}
.main_user_video_call_video_section .zoom{
    position: absolute!important;
    z-index: 9999999999!important;
}
p.title_enhanced_desktop {
    text-align: center;
    margin-bottom: 14px;
    font-size: 18px;
    color: #094af9;
    font-weight: 600;
}
.assign_class_main_popup_outer_container .assign_class_main_popup_inner_container .close_popup_button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 23px;
    z-index: 99999999!important;
    height: 23px;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
    cursor: pointer;
    font-size: 15px;
}

.assign_create_switch_button {
    display: flex;
    margin: 10px 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.assign_create_switch_button button.assign_button {
    font-size: 13px;
    padding: 13px 12px;
    margin: auto;
    font-weight: 600;
    color: #001fff;
    background: #fff;
    border: 2px solid transparent;
}

.assign_create_switch_button button.assign_button.active {
    border: 2px solid #0d6efd!important;
}
.assign_class_main_popup_outer_container .assign_class_main_popup_inner_container {
    margin: auto;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    width: 398px;
    min-height: 335px;
}

.assign_class_main_popup_outer_container .assign_class_main_popup_inner_container .close_popup_button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 23px;
    height: 23px;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
    cursor: pointer;
    font-size: 15px;
}
.payment_popup_main_container .payment_popup_inner_div {
    margin: auto;
    background: #fff;
    position: relative;
    padding: 51px;
    border-radius: 13px;
    box-shadow: 0px 1px 20px 0px #b1b1b18c;
}
.multiple_select_input.form-select .search-wrapper.searchWrapper input {
    height: 29px;
    padding: 0px;
}
.student_attendance_assignment_main_container {
    padding: 0 139px;
    overflow: auto;
    height: calc(100vh - 65px);
}
.student_payment_subscription_plan_success_main_section {
    display: flex;
    width: 100%;
    height: 100vh;
}
.assignment_section .assignment_project_list a {
    display: flex;
    cursor: pointer;
}

.assignment_project_list_for_student {
    display: flex;
    padding: 5px 10px;
}

.assignment_project_list_for_student a {
    display: flex;
    margin: 0px;
}
.assignment_section .assignment_project_list {
    display: flex;
    padding: 4px 10px;
}
.assignment_section .assignment_project_list img {
    width: 21px;
}
.student_payment_subscription_plan_success_main_section .student_payment_subscription_plan_inner_div {
    margin: auto;
    text-align: center;
}

.student_payment_subscription_plan_success_main_section .student_payment_subscription_plan_inner_div h2 {
    font-size: 40px;
    margin-bottom: 33px;
}

.student_payment_subscription_plan_success_main_section .student_payment_subscription_plan_inner_div img.success_check {
    width: 207px;
    margin-bottom: 30px;
}
.payment_popup_inner_div .header_payment_close_button {
    width: 34px;
    height: 34px;
    background: #a1a1a1;
    position: absolute;
    right: 0px;
    cursor: pointer;
    top: 0px;
    color: #fff;
    display: flex;
}

.payment_popup_inner_div .header_payment_close_button i {
    margin: auto;
}
.demo_classes_section_subject_icon_name .icon_sub svg {
    margin: auto;
    padding: 6px;
    width: 50px;
}
.demo_classes_section_subject_icon_name .name_section {
    width: 80%;
}
.demo_classes_main_section_div .demo_classes_section_loop .class_time_date_demo {
    font-size: 14px;
}
.demo_classes_section_teacher_icon_name {
    margin-top: 5px;
    padding: 0px;
    padding-left: 3px;
}

.demo_classes_section_subject_icon_name .name_section .name_section1 {
    font-weight: 600;
    font-size: 1.9vh;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 172px;
}

.demo_classes_section_subject_icon_name .name_section .name_section2 {
    font-size: 12px;
    color: #8b8b8b;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 172px;
    white-space: nowrap;
}

.demo_classes_main_section_div .demo_classes_section_loop .class_time_date_demo {
    font-size: 1.9vh;
    font-weight: 600;
    padding-left: 13px;
    color: #f7931e;
}

.demo_classes_section_teacher_icon_name .teacher_detail_section {
    display: flex;
    margin-left: 15px;
}

.demo_classes_section_teacher_icon_name .teacher_detail_section .teacher_font_icon {
    font-size: 14px;
    text-align: center;
}

.demo_classes_section_teacher_icon_name .teacher_detail_section .teacher_name_section {
    font-size: 1.8vh;
    margin-left: 14px;
}

.demo_classes_section_loop .take_demo_button button.theme_btn {
    font-size: 1.8vh;
    padding: 9px 20px;
    width: 80%;
}

.user_profile_fixed_section_main_container {
    position: absolute;
    right: 10px;
    top: 0px;
    height: 100vh;
    overflow: auto;
    width: 30%;
    background-image: linear-gradient(180deg, #ffa236, #ffdcb4);
}
.student_statur.present {
    color: #588f00;
    font-size: 12px;
    font-weight: 600;
}
.accordion-body hr {
    background: #767676!important;
    width: 100%;
}

.accordion-body {
    padding: 10px;
}
.accordion-body .accordion-body-inner-data {
    border: 1px solid #ccc;
    padding: 10px;
}
.student_statur.absent {
    color: #ff0404;
    font-size: 12px;
    font-weight: 600;
}
.user_profile_fixed_section_main_container .user_profile_main_section {
    margin-top: 10vh;
    padding: 0px 45px;
}
.user_profile_fixed_section_main_container .user_profile_icon_user_section {
    text-align: center;
}
.user_profile_fixed_section_main_container .user_profile_main_section .class_standared_text {
    font-size: 15px;
    margin-top: 0px;
    color: #4c4c4c;
}
.user_profile_fixed_section_main_container .user_profile_main_section .user_icon_profile {
    width: 70px;
    height: 70px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 100%;
    overflow: hidden;
    font-size: 25px;
    background: #fff;
    font-weight: 600;
    margin-bottom: 10px;
}

.user_profile_fixed_section_main_container .user_profile_main_section .user_icon_profile_name {
    font-size: 2.5vh;
    font-weight: 600;
    color: #444;
}
.user_profile_personal_detail_batch_section {
    padding: 10px;
    border-radius: 10px;
    background: #ffffff96;
    font-size: 2vh;
    color: #ff4800;
    margin-left: 19px;
}

.user_profile_personal_detail_batch_section .user_batch_icon_section {
    border-rignt: 1px solid #ccc;
}
.user_profile_personal_detail_heading {
    color: #444;
    font-size: 2.1vh;
}
.user_profile_personal_detail .user_profile_personal_detail_text {
    font-size: 1.9vh;
    margin-left: 24px;
}
.teacher_video_class_container_inner_row {
    height: 100vh;
    overflow: hidden;
    background: #000000;
    position: relative;
}

.div_for_student_call {
    position: absolute;
    top: 0px;
    right: 119px;
    width: 308px;
    padding-top: 76px;
    height: 100vh;
}

.div_for_student_call .student_in_class {
    border-radius: 15px;
    border: 5px solid #fff;
    overflow: hidden;
    width: 264px;
    margin-top: 18px;
    margin-bottom: 18px;
}


.side_other_video_with_details {
    background-image: linear-gradient(180deg, #ff7037, #ff601f);
    padding: 0px;
}

.side_other_video_with_details .student_in_class {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.side_other_video_with_details .student_in_class video.other_video_peer_connection {
    width: 100%;
    height: 100%;
    border: 5px solid #fff;
    border-radius: 19px;
}
.side_my_video_with_details {
    background-image: linear-gradient(180deg, #ff7037, #ff601f);
    padding: 0px;
    padding-left: 12px;
}

div#main_user_video_call_video_section video.my_video_peer_connection {
    width: 100%;
}
.center_white_board_video_with_details {
    background: #e9e9e9;
    overflow: auto;
    height: calc(100vh - 0px);
    margin: 0px;
    padding: 0px!important;
}
.side_my_video_with_details .video_class_description {
    padding: 14px;
    overflow: auto;
    height: calc(100vh - 221px);
}
.supported_tools_main_ctrl {
    display: flex;
    margin: auto;
}
.supported_tools_main_ctrl.test {
    display: flex;
    padding-top: 17px;
}

.canvas_editor_inner_main_section {
    background: #6a6a6a;
    border-radius: 18px;
    display: flex;
}
.supported_tools_main_ctrl {
    justify-content: center;
    left: 0;
    max-width: 50%;
    right: 0;
}
.st_colors {
    align-items: center;
    display: flex;
}
.color_varians_main_ctrl, .cv_innr_ctrl {
    align-items: center;
    display: inline-flex;
}
.colored_icon_ctrl {
    align-items: center;
    border-radius: 1px;
    cursor: pointer;
    display: inline-flex;
    height: 14px;
    justify-content: center;
    margin: 0 6px;
    min-width: 14px;
    opacity: 1;
    position: relative;
    text-align: center;
    transition: all .5ms ease-in-out;
    -moz-transition: all .5ms ease-in-out;
}
.colored_icon_ctrl.active:before {
    align-items: center;
    color: #fff;
    content: "\f00c";
    display: inline-flex;
    font-family: Font Awesome\ 5 Free;
    font-size: 10px;
    font-weight: 900;
    height: 14px;
    height: 100%;
    justify-content: center;
    left: 0;
    line-height: 1.6;
    margin: auto;
    position: absolute;
    top: 0;
    vertical-align: middle;
    width: 100%;
}
.colored_icon_ctrl.active:after {
    border: 2px solid #0e9aef;
    border-radius: 1px;
    bottom: 0;
    content: "";
    height: 24px;
    left: -5px;
    position: absolute;
    right: 0;
    top: -5px;
    width: 24px;
}
.video_class_description_details .detail_main_h {
    line-height: 30px;
    font-size: 2.3vh;
    color: #fff;
}
.video_class_all_students_loop {
    line-height: 30px;
    font-size: 2.3vh;
    color: #fff;
    display: flex;
}

.video_class_all_students_loop .name_initial {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #000;
    text-align: center;
    font-weight: 600;
    padding: 6px;
    margin-right: 9px;
}
.isPortraitMode_mode_popup{
    display: flex;
    width: 100%;
    height: 100vh;
    background: #fff;
    text-align: center;
    color: #1a1e21;
    font-size: 20px;
    font-weight: bold;
}
.isPortraitMode_mode_popup p{
    margin: auto;
}

.video_class_all_students_loop .student_name {
    margin-top: 4px;
}

.video_class_all_students_loop .name_initial {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #000;
    text-align: center;
    font-weight: 600;
    padding: 6px;
    margin-right: 9px;
}
.center_white_board_video_with_details .center_white_board_video_main_container {
    background: #fff;
    overflow: hidden;
    height: calc(100vh - 160px);
    border: 2px solid #4c4c4c;
}
.modal_screenshot_image_canvas{
    height: calc(100vh - 300px)!important;
}
.assign_class_main_popup_inner_container button {
    width: 100%;
}
.video_class_all_students_loop .student_name {
    margin-top: 4px;
}

.video_class_description_details_first_col {
    background: #fdf8ff61;
    padding: 18px;
    border-radius: 23px;
}

.video_class_description_details_first_col h1 {
    font-size: 2.8vh;
    color: #fff;
}
.ion-page:has(#teacher_video_class_container) .footer_main_tab_section {
    display: none!important;
}
.side_other_video_with_details .other_video_peer_connection {
    width: 100%;
    margin-top: 15px;
    border: 2px solid #fff;
    border-radius: 0px;
}
.call_ent_button_section {
    text-align: center;
}
.student_pay_for_subscription_main_page p {
    line-height: 35px;
}

.student_pay_for_subscription_main_page .classes_section_loop {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.student_pay_for_subscription_main_page .classes_section_loop p span {
    font-weight: 600;
    color: #000;
}
.student_pay_for_subscription_main_page {
    width: 100%;
}
.student_pay_for_subscription_main_page .classes_section {
    display: flex;
    flex-flow: wrap;
}
.student_pay_for_subscription_main_page .classes_section_loop p {
    font-size: 2.3vh;
}
.student_pay_for_subscription_main_page .classes_section {
    overflow: auto;
    margin-bottom: 36px;
}
.student_pay_for_subscription_main_page .classes_section_loop {
    margin-right: 6px;
    width: 32%;
}
.student_pay_for_subscription_main_page .classes_section_loop {
    margin-right: 6px;
}
.call_ent_button_section button.end_call_button {
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    background: #cf0000;
}
.scheduled_classes_main_section {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 18px;
    box-shadow: 0px 0px 20px 3px #ff5e0038;
}
.time_text_event_container_in_calander {
    line-height: 16px;
    padding: 9px;
    font-size: 10px;
}

.time_text_event_container_in_calander p {
    color: #fff;
    font-size: 2vh!important;
}

.time_text_event_container_in_calander p span {
    font-weight: 600;
}
.classes_section_total_payable button.theme_btn.pay_button {
    padding: 12px 50px!important;
}

.scheduled_classes_main_section .fc-view-harness {
    height: calc(100vh - 290px)!important;
}
.classes_section_total_payable h3 {
    margin-top: 10px;
    font-size: 3vh;
}
.call_ent_button_section button.mute_call_button {
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    background: #8d8d8d;
}
.student_pay_for_subscription_main_page h1 {
    font-size: 3.4vh;
}
.student_pay_for_subscription_main_page h3 {
    font-size: 2.7vh;
}
.user_profile_fixed_section_main_container .facebook_style_loader_container {
    padding: 47px;
    margin-top: 47px;
}
.classes_section_total_payable.month {
    margin-bottom: 10px;
}
.student_pay_for_subscription_main_page .classes_section_loop p {
    font-size: 2.3vh;
}

.classes_section_total_payable.month {
    margin-bottom: 10px;
}

.classes_section_total_payable.month select.total_month_input_select {
    border: 0px;
    border-radius: 5px;
    padding: 8px 17px;
}

.classes_section_total_payable.month select.total_month_input_select option {
    font-size: 2.3vh;
}
.canvas_editor_tools_main_section {
     padding: 3px;
     background: #6a6a6a;
    height: 80px;
 }
.undo_redo_disable {
    opacity: .5;
}
.canvas_editor_inner_main_section {
    background: #6a6a6a;
    border-radius: 18px;
    display: flex;
}
.canvas_editor_tools_main_section button.canvas_tool {
    padding: 9px;
    margin: auto;
    background: #fff0;
    border-radius: 10px;
    border: 1px solid #b5b5b5;
}

.canvas_editor_tools_main_section button.canvas_tool.active {
    background: #ff6323;
    border: 1px solid #ff6323;
}
.modal_screenshot_image_cursor_move{
    cursor: move!important;
}
.modal_screenshot_image_cursor_crosshair{
    cursor: crosshair!important;
}




/*upload css*/

.js,
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 100%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.6rem 0px;
    /* 10px 20px */
}
.upload.container_section {
    display: flex;
}
.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

/* style 1 */

.inputfile-1 + label {
    color: #f1e5e6;
    background-color: #d3394c;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #722040;
}

/* style 2 */

.inputfile-2 + label {
    color: #d3394c;
    border: 2px solid currentColor;
}

.inputfile-2:focus + label,
.inputfile-2.has-focus + label,
.inputfile-2 + label:hover {
    color: #722040;
}

/* style 3 */

.inputfile-3 + label {
    color: #d3394c;
}

.inputfile-3:focus + label,
.inputfile-3.has-focus + label,
.inputfile-3 + label:hover {
    color: #722040;
}

/* style 4 */

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

/* style 5 */

.inputfile-5 + label {
    color: #d3394c;
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
    color: #722040;
}

.inputfile-5 + label figure {
    width: 100px;
    height: 135px;
    background-color: #d3394c;
    display: block;
    position: relative;
    padding: 30px;
    margin: 0 auto 10px;
}

.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
    background-color: #722040;
}

.inputfile-5 + label figure::before,
.inputfile-5 + label figure::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
}

.inputfile-5 + label figure::before {
    border-top: 20px solid #dfc8ca;
    border-left: 20px solid transparent;
}

.inputfile-5 + label figure::after {
    border-bottom: 20px solid #722040;
    border-right: 20px solid transparent;
}

.inputfile-5:focus + label figure::after,
.inputfile-5.has-focus + label figure::after,
.inputfile-5 + label:hover figure::after {
    border-bottom-color: #d3394c;
}

.inputfile-5 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

/* style 6 */

.inputfile-6 + label {
    color: #d3394c;
}

.inputfile-6 + label {
    border: 1px solid #d3394c;
    background-color: #f1e5e6;
    padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
    border-color: #722040;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}
.user_profile_edit_main_container {
    display: flex;
    height: 100vh;
    width: 100%;
}

.inputfile-6 + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
}
.section-title h2 {
    font-size: 4vh;
}
a.nav-item.nav-link.active {
    color: #f7931e!important;
}
.user_profile_edit_main_container_inner_section .personal_prof_form_2 {
    height: 100vh;
    padding: 124px!important;
    border-radius: 0px!important;
}


.user_profile_edit_main_container_inner_section button.theme_btn {
    width: 100%;
}
.user_profile_edit_main_container_inner_section .personal_prof_form_2  .profile_form_main_inner_body {line-height: 37px;}
.user_profile_edit_main_container_inner_section .personal_prof_form_2 .profile_form_main_inner_body input.form-control
, .user_profile_edit_main_container_inner_section .personal_prof_form_2 .profile_form_main_inner_body .form-group select {
    font-size: 2.3vh;
    border-radius: 5px;
    text-overflow: ellipsis;
    color: #7a7a7a;
    padding: 12px 10px;
}
.accordion-body p{
    font-size: 2.2vh;
}
.accordion-body h6{
    font-size: 2.4vh;
}
.text-list li:nth-child(3)::before {
    background-color: #bf31b0;
}
.text-list li:nth-child(4)::before {
    background-color: #36bf31;
}
.text-list li:nth-child(5)::before {
    background-color: #1e8df7;
}
.text-list li:nth-child(6)::before {
    background-color: #6d19d1;
}
.text-list li:nth-child(7)::before {
    background-color: #8d8d8d;
}
.text-list li:nth-child(8)::before {
    background-color: #852c2c;
}

.footer-log a.logo img {
    width: 87px;
}
.accordion-item:first-of-type .accordion-button{
    font-size: 2.4vh;
}
.user_profile_edit_main_container_inner_section {
    width: 100%;
}
.inputfile-6 + label strong {
    height: 100%;
    color: #f1e5e6;
    background-color: #d3394c;
    display: inline-block;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: #722040;
}

@media screen and (max-width: 50em) {
    .inputfile-6 + label strong {
        display: block;
    }
}
.slider-img-box-two {
    position: relative;
    z-index: 3;
}
.slider-img-box-two .shape-avatar-bg {
    position: absolute;
    left: 30px;
    z-index: -2;
    top: 60px;
    -webkit-animation: rotation infinite 55s linear;
    animation: rotation infinite 55s linear;
    max-width: unset!important;
}
.slider-img-box-two .feature.tag_01 {
    top: 220px;
    left: -12px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 20px;
    background: transparent;
    -webkit-box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz--backdrop-filter: blur(10px);
    -o--backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.03);
}
.feature.tag_01 {
    top: 60px;
    left: -32px;
    font-weight: 600;
}
.slider-img-box-two .feature {
    color: #ffffff;
    border-radius: 20px;
    background: transparent;
    -webkit-box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    z-index: 2;
}
.feature.tag_01 span {
    background-color: #F14D5D;
}
.slider-img-box-two .feature.tag_02 {
    right: 253px;
    top: 32px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 20px;
    background: transparent;
    -webkit-box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.03);
}
.feature.tag_02 span {
    background-color: #8E66FF;
}
.slider-img-box-two .feature.tag_03 {
    color: #ffffff;
    border-radius: 20px;
    left: auto;
    right: -210px;
    font-weight: 600;
    bottom: 250px;
    background: transparent;
    -webkit-box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    box-shadow: 5px 15px 60px rgb(27 33 47 / 60%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.03);
    z-index: 2;
}
.slider-img-box-two .chose-img-wrapper .video-wrapper {
    position: absolute;
    z-index: 1;
    right: auto;
    left: 62px;
    bottom: 178px;
}
.slider-img-box-two .chose-img-wrapper .video-wrapper .popup-video {
    width: 90px;
    height: 90px;
    background: transparent;
    -webkit-filter: drop-shadow(5px 15px 60px rgba(27, 33, 47, 0.6));
    filter: drop-shadow(5px 15px 60px rgba(27, 33, 47, 0.6));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.03);
}
.chose-img-wrapper .video-wrapper .popup-video {
    color: #f7931e;
    width: 90px;
    height: 90px;
    line-height: 90px;
    background: #ffffff;
    -webkit-filter: drop-shadow(5px 15px 60px rgba(27, 33, 47, 0.1));
    filter: drop-shadow(5px 15px 60px rgba(27, 33, 47, 0.1));
}
.popup-video {
    color: #ffffff;
    background: #f7931e;
    border-radius: 50%;
    font-size: 14px;
    line-height: 100px;
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    position: relative;
    z-index: 1;
    -webkit-animation: pulseBig infinite 5s linear;
    animation: pulseBig infinite 5s linear;
}
.slider-img-box-two .chose-img-wrapper .chose_06 {
    position: absolute;
    left: -74px;
    bottom: 42px;
    z-index: 2;
}
.slider-img-box-two .chose-img-wrapper .chose_05 {
    margin-top: 120px;
    margin-left: -37px;
    max-width: unset!important;
}
.chose-img-wrapper .video-wrapper {
    position: absolute;
    z-index: 1;
    right: 82px;
    bottom: 133px;
}
.feature.tag_03 span {
    background-color: #41C485;
}
.feature.tag_03 {
    left: 128px;
    bottom: -60px;
    font-weight: 600;
}
.feature.tag_02 {
    right: 10px;
    top: 152px;
    font-weight: 600;
}
.feature span {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    background-color: #f7931e;
    margin-right: 23px;
}
.feature {
    border-radius: 20px;
    background: #ffffff;
    -webkit-box-shadow: 5px 15px 60px rgb(27 33 47 / 10%);
    box-shadow: 5px 15px 60px rgb(27 33 47 / 10%);
    font-size: 20px;
    color: #1B212F;
    font-weight: 600;
    display: inline-block;
    padding: 20px 30px 20px 20px;
    position: absolute;
    z-index: 1;
}
button.submit {
    display: block;
    margin: auto;
    padding: 10px 20px;
    background-color: #d3394c;
    color: #ffffff;
    border: none;
    font-size: 2.4vh;
    font-weight: bold;
}
button.submit:hover {
    cursor: pointer;
}
.assignment_file_pdf_section {
    display: flex;
    flex-wrap: wrap;
}

.assignment_file_pdf_section img {
    width: 23px;
    margin-right: 10px;
}
.inputfile-4 + label{
    font-size: 2.4vh;
}
.edit_teacher_main_popup_outer_container {
    position: fixed;
    background: #ffffffeb;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
}
.student_assignment_section {
    border: 1px solid #ccc;
    padding: 6px;
    margin: 6px;
    border-radius: 10px;
}
.edit_teacher_main_popup_outer_container .edit_teacher_main_main_popup_inner_container {
    margin: auto;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    width: 780px;
    min-height: 335px;
}
.edit_teacher_main_popup_outer_container .edit_teacher_main_main_popup_inner_container .close_popup_button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 23px;
    height: 23px;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
    cursor: pointer;
    font-size: 15px;
}
/*upload css*/

.container-fluid a {
    cursor: pointer;
}
@media (max-width: 767px) {
    .main-header-area {
        padding-left: 0;
        padding-right: 0;
    }
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 999999;
}
.preloader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
}
.preloader span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #f7931e;
    -webkit-animation: preloader 1.3s linear infinite;
    animation: preloader 1.3s linear infinite;
}
.preloader span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
}
.preloader span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #f7931e;
    -webkit-animation: preloader 1.3s linear infinite;
    animation: preloader 1.3s linear infinite;
}
.ion-page-invisible{
    opacity: 1!important;
}
.mobile-tour-section-img {
    display: flex;
    background: #fff;
}

.mobile-tour-section-img .img-fluid {
    margin: auto;
}
.blog-details-box h2 {
    font-size: 46px;
    line-height: 1.4;
    margin-bottom: 15px;
}
.blog-details-box .blog-details-img {
    display: inline-block;
    border-radius: 5px;
    width: 100%!important;
    height: auto!important;
    border: 3px dashed #f7931e;
}
.blog-details-title {
    font-size: 30px;
}
.blog-details-box p {
    text-align: justify;
    margin-top: 12px;
}
section.page-title-area {
    background-size: cover;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    min-height: 200px;
    position: relative;
    z-index: 1;
    margin-top: 135px;
}
.page-title-wrapper {
    position: relative;
    z-index: 2;
}
.page-title-wrapper .page-title {
    color: #1B212F;
    font-size: 44px;
    z-index: 1;
}
.tab-content > .active {
    display: block;
}
.plan {
    background-color: #8E66FF;
    padding: 50px 50px 30px 50px;
    border-radius: 5px;
}
.plan .pr__header {
    border-bottom: 2px solid rgba(168, 165, 165, 0.2);
}
.plan .pr__header h2 {
    color: #ffffff;
    font-size: 46px;
    margin-bottom: 25px;
}
.plan .pr__header h5 {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 30px;
}
.plan .pr__header .pr-icon {
    margin-bottom: 25px;
}
.plan .pr__body .old-price {
    color: rgba(255, 255, 255, 0.15);
    font-size: 30px;
    font-weight: 500;
    text-decoration: line-through;
}
.plan .pr__body h2 b {
    color: #ffffff;
    font-size: 40px;
}
.plan .pr__body h2 b sup {
    font-size: 14px;
    top: -17px;
}
.plan .pr__body h2 b .new-price {
    font-size: 16px;
    font-weight: 500;
}
.plan .pr__body .price-list li {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 22px;
    padding-left: 42px;
    position: relative;
    z-index: 1;
}
.plan .pr__body .price-list li::before {
    content: '\f00c';
    position: absolute;
    font-family: 'Font Awesome 5 pro';
    font-size: 18px;
    left: 0;
    top: 0;
    z-index: 1;
}

.plan-2 {
    background-color: #f7931e;
}
.plan-2a {
    background-color: #F14D5D;
}
.contact-img {
    position: relative;
    z-index: 1;
}
.contact-img::before {
    content: '';
    position: absolute;
    background-color: rgba(255, 114, 58, 0.1);
    width: 475px;
    height: 475px;
    border-radius: 50%;
    left: -98px;
    top: -55px;
    z-index: -1;
}
.section-title {
    position: relative;
    z-index: 2;
}

.single-contact-box {
    background-color: rgba(142, 102, 255, 0.05);
    padding: 45px 115px;
    border-radius: 5px;
}
.single-contact-box .contact__iocn {
    background: #8E66FF;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    line-height: 59px;
    margin-right: 30px;
    display: inline-block;
    float: left;
}
.single-contact-box .contact__text h5 {
    color: #1B212F;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
}
.cb-2 {
    background-color: rgba(241, 77, 93, 0.05);
}
.single-contact-box {
    background-color: rgba(142, 102, 255, 0.05);
    padding: 45px 115px;
    border-radius: 5px;
}
.cb-2 .contact__iocn {
    background-color: #F14D5D;
}
.single-contact-box .contact__iocn {
    background: #8E66FF;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    line-height: 59px;
    margin-right: 30px;
    display: inline-block;
    float: left;
}
.single-contact-box .contact__text h5 {
    color: #1B212F;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
}
.cb-3 {
    background-color: rgba(255, 114, 58, 0.05);
}
.single-contact-box {
    background-color: rgba(142, 102, 255, 0.05);
    padding: 45px 115px;
    border-radius: 5px;
}
.cb-3 .contact__iocn {
    background-color: #f7931e;
}
.single-contact-box .contact__iocn {
    background: #8E66FF;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    line-height: 59px;
    margin-right: 30px;
    display: inline-block;
    float: left;
}
.navbar-nav .nav-item .nav-link {
    color: #505050;
    font-size: 20px;
    line-height: 1;
    display: inline-block;
    position: relative;
    margin: 0 37px;
    padding: 30px 0;
}