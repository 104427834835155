.main_container_app_section {
    .demo_classes_main_section {
        width: 100% !important;
        padding: 0px !important;
        box-shadow: none !important;
    }
    .class_data_main_table_section .body {
        margin-top: 0px !important;
        text-align: center;
        font-size: 11px!important;
    }
    .student_pay_for_subscription_main_page {
        width: 100%!important;
        background: #c093ff;
        border-radius: 0px!important;
        overflow: hidden;
        margin: 0px!important;
        padding: 29px;
        height: calc(100vh - 130px);
        h1{
            font-size: 18px!important;
        }
        p{
            font-size: 14px;
            color:#000000;
        }
        h2{
            font-size: 17px!important;
        }
        h3{
            font-size: 15px!important;
        }
        .classes_section_loop{
            p{
                font-size: 14px!important;
                color:#000000!important;
            }
        }
        .classes_section_total_payable{
            h3{
                font-size: 15px!important;
            }
        }
        .classes_section_total_payable button.theme_btn.pay_button {
            padding: 5px 39px !important;
            font-size: 15px;
            height: 35px;
            border-radius: 0px;
        }
    }
    .no_demo_classes_div_section {
        text-align: center;
        font-size: 15px;
        line-height: 24px;
        color: #ff500a;
    }
    .no_demo_classes_div_section img {
        width: 30%!important;
    }
    .demo_classes_main_section_div {
        display: block !important;
    }

    .demo_classes_main_section_div .demo_classes_section_loop {
        padding: 6px !important;
        width: 100% !important;
        margin: 10px 0px!important;
    }

    .demo_classes_main_section_div .demo_classes_section_loop .class_time_date_demo {
        font-size: 11px !important;
    }

    .demo_classes_section_subject_icon_name .name_section .name_section1 {
        width: 113px !important;
    }
    .demo_classes_main_section_div .demo_classes_section_loop .take_demo_button {
        text-align: center;
    }
    .demo_classes_section_subject_icon_name .name_section .name_section2 {
        width: 113px !important;
    }

    .demo_classes_section_subject_icon_name .icon_sub {
        width: 55px;
        height: 55px;
        margin-right: 3px;
    }

    .demo_classes_main_section_div .demo_classes_section_loop {
        padding: 6px !important;
        width: 100% !important;
        border: 1px solid #f1f1f1;
        margin-bottom: 18px;
        box-shadow: 0px 0px 20px 3px #c5c5c538;
    }

    .demo_classes_section_teacher_icon_name .teacher_detail_section .teacher_name_section {
        font-size: 12px !important;
        margin-left: 14px !important;
    }

    .demo_classes_section_teacher_icon_name .teacher_detail_section .teacher_font_icon {
        font-size: 13px !important;
    }

    .demo_classes_section_teacher_icon_name .teacher_detail_section {
        color: #525252 !important;
        margin-top: 5px !important;
    }

    .demo_classes_main_section_div {
        margin-top: 20px;
    }

    .demo_classes_section_loop .take_demo_button button.theme_btn {
        font-size: 11px !important;
        padding: 9px 15px!important;
    }
    .main_container_app_section .demo_classes_main_section.your_classes {
        box-shadow: 0px 0px 20px 3px rgb(197 197 197 / 22%)!important;
    }
    .class_list_table.header_row {
        font-size: 13px!important;
    }
    .class_list_table.header_row {
        border-bottom: 1px solid #ccc!important;
        line-height: 18px!important;
        padding-top: 0px!important;
        padding-bottom: 10px!important;
    }

    .class_data_main_table_section {
        box-shadow: 0px 0px 20px 3px rgb(197 197 197 / 22%)!important;
    }
    .class_list_table.header_row .header {
        padding-right: 0px!important;
        padding-left: 0px!important;
        text-align: center!important;
    }
    .icon_main_col .name_section {
        margin: 0px !important;
        text-align: center;
        font-size: 13px;
    }
    .class_list_table .icon_setion {
        width: 40px !important;
        height: 40px !important;
        padding: 5px!important;
        margin: auto;
    }
    .class_data_main_table_section .icon_main_col {
        display: block!important;
    }
    .class_data_main_table_section .body {
        margin-top: 0px!important;
        text-align: center;
    }
    .class_list_table {
        font-size: 13px!important;
    }
    .classes_main_section_div {
        margin-bottom: 30px!important;
    }
    .class_list_table .icon_setion svg {
        margin: auto;
    }

}
.footer_main_tab_section {
    background: #fff;
    .class_list_table {
        font-size: 13px;
    }

    .classes_main_section_div {
        margin-bottom: 30px;
    }

    .tab_bottom_section {
        text-align: center;
        display: block;
        font-size: 11px;
    }

    .tab_bottom_section svg {
        width: 21px;
        fill: #5c5c5c;
    }
    .menu_loop_section_inner_section_tab.active .tab_bottom_section svg {
        fill: #f7931e;
    }
    .menu_loop_section_inner_section_tab.active .tab_bottom_section {
        color: #f7931e;
    }
}

.student_video_call_section_container {
    .main_user_video_call_video_section {
        padding: 10px;
    }

    .main_user_video_call_video_section video {
        background: #fff;
        border: 1px solid #cccccc85;
        box-shadow: 1px 1px 9px 0px #4e4e4e21;
    }
    .canvas_tool {
        padding: 9px;
        margin: auto;
        background: #fff0;
        border-radius: 0px;
    }
    .canvas_editor_tools_main_section {
        border-radius: 0px;
        padding: 4px;
    }
    .canvas_editor_tools_main_section button.canvas_tool {
        padding: 10px;
    }
    .center_white_board_video_with_details .center_white_board_video_main_container {
        background: #fff;
        margin: 0px 0px;
        border: 3px solid #4c4c4c;
        border-radius: 0px;
        overflow: hidden;
        height: calc(100vh - 320px);
    }
    video.my_video_peer_connection {
        min-height: 250px;
    }
    .center_white_board_video_with_details {
        padding: 10px;
    }
    height: calc(100vh - 55px);
    background: #e9e9e9;
}
.mobile_video_call_header_section {
    background: #ff6323;
    font-size: 17px;
    color: #fff;
    padding: 10px;
}
.main_body_content_login_mobile{
    .section-title h5 {
        font-size: 1.5vh;
    }
    .section-title h2 {
        font-size: 2.5vh!important;
    }
    .section-title p {
        font-size: 1.7vh;
    }
    ul.text-list li {
        font-size: 1.8vh;
    }
    .copyright h5 {
        font-size: 1.3vh;
    }
    .copy-right-area-mobile {
        background: #d7d7d7;
        padding: 16px;
    }
}
.main_signup_component_body_mobile{
    .comments-form input {
        font-size: 14px;
        height: 45px;
        border-radius: 0px;
        padding-left: 14px;
    }
    .comments-form input::placeholder{
        font-size: 14px;
    }
    .comments-form textarea {
        font-size: 14px;
        height: 250px;
        border-radius: 0px;
        padding-top: 14px;
        padding-left: 14px;
    }
    .comments-form textarea::placeholder{
        font-size: 13px;
    }
    .contact-form-wrapper .message_btn {
        font-size: 14px;
        padding: 15px 84px;
        border-radius: 20px;
        margin-top: 12px;
    }
}
.user_create_profile_main.mobile_container{
    .user_create_profile_main_row {
        background: #c093ff;
        border-radius: 0px!important;
        width: 100%;
        overflow: hidden;
        margin: 0px!important;
        padding: 29px;
    }
    .profile_form_main_inner_body {
        padding: 0px 5px!important;
    }
    .profile_form_header {
        font-size: 18px!important;
        color: #fff;
        font-weight: 600;
    }
    .profile_form_main_inner_body .form-group label {
        font-size: 14px!important;
    }
    .profile_form_main_inner_body .form-group input, .profile_form_main_inner_body .form-group select {
        font-size: 14px;
        border-radius: 0px;
        text-overflow: ellipsis;
        color: #7a7a7a;
    }
    .user_create_profile_main_row .personal_prof_form_2 {
        background: none;
        border-radius: 0px;
        width: 100%;
        margin: 0px;
        padding: 0px;
        height: auto!important;
    }
    .user_create_profile_main_row .personal_prof_form {
        background: none;
        border-radius: 0px;
        width: 100%;
        margin: 0px;
        margin-top:15px;
        padding: 0px;
        height: auto!important;
    }
    .profile_form_main_inner_body2 label {
        font-size: 14px;
    }
    .profile_form_main_inner_body2 .form-group input, .profile_form_main_inner_body2 .form-group select {
        font-size: 14px;
        border-radius: 0px;
    }
    .profile_form_main_inner_body label.form-check-label {
        font-size: 14px;
        color: #fff;
    }
}
.student_payment_subscription_plan_success_main_section.mobile{
    .student_payment_subscription_plan_inner_div h2 {
        font-size: 26px;
        margin-bottom: 18px;
    }
    .student_payment_subscription_plan_inner_div img.success_check {
        width: 30%;
        margin-bottom: 15px;
    }
    p{
        font-size: 15px;
    }
    .go_to_dashboard_class_link {
        background: #f7931e;
        color: #fff;
        padding: 10px 34px;
        margin-top: 15px;
        display: block;
        font-weight: 600;
        font-size: 15px;
    }
}
.main_body_content_section.all_student_subject_main_container.mobile{
    padding: 0px;
    background-image:none!important;
    .main_body_content_section_inner_calander {
        margin-top: 0px !important;
    }

    .heading_sch{
        display: none!important;
    }
    h2{
        font-size: 18px;
    }
    .fc-direction-ltr {
        height: calc(100vh - 149px)!important;
    }
    .scheduled_classes_main_section {
        border-radius: 0px;
        padding: 10px;
        margin: 0px!important;
        background: #fff;
        box-shadow: unset!important;
        border: unset;
    }
    .fc .fc-scrollgrid-liquid {
        height: 100%;
        border: 1px solid #ddd;
    }
    .fc-today-button{
        display: none!important;
    }
    .fc-button-group{
        .fc-icon{
            font-size: 14px!important;
        }
        .fc-button{
            padding: 8px;
            font-size:0px!important;
        }
    }
    .fc .fc-col-header-cell-cushion{
        padding: 0px!important;
        font-size: 15px;
    }
    .fc .fc-daygrid-day-number {
        padding: 0px;
        font-size: 12px!important;
    }
    .time_text_event_container_in_calander p{
        font-size: 12px!important;
    }
    .time_text_event_container_in_calander {
        padding: 3px;
    }
    .fc .fc-scrollgrid-section-body table{
        tbody{
            display: block!important;
        }
    }
    .fc .fc-scrollgrid-section{
        .fc-scroller{
            overflow: auto auto!important;
        }
    }
}
.student_attendance_assignment_main_container.mobile{
    margin: 0px!important;
    padding: 36px!important;

    .accordion-header button {
        font-size: 15px!important;
    }

    .accordion-collapse p {
        font-size: 14px;
    }

    .accordion-collapse a {
        font-size: 14px;
    }

    .accordion-collapse h6 {
        font-size: 15px;
    }

    .upload.container_section svg {
    }

    .upload.container_section label {
        padding: 10px 0px;
    }

    .upload.container_section label figure {
        width: 72px;
        height: 69px;
        margin: 0 auto 0px;
    }

    .upload.container_section button {
        padding: 9px 16px;
        font-size: 12px!important;
    }
}
.user_profile_edit_main_container.mobile{
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: calc(100vh - 128px);
    overflow: auto;
    .user_profile_edit_main_container.mobile {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .user_create_profile_main_row {
        margin: 0px!important;
    }

    .user_profile_edit_main_container_inner_section .personal_prof_form_2 {
        padding: 0px 24px !important;
        height: auto!important;
        border-radius: 0px;
        overflow: auto;
        margin: 0px!important;
    }

    .user_profile_edit_main_container_inner_section .personal_prof_form_2 input,
    .user_profile_edit_main_container_inner_section .personal_prof_form_2 select,
    .user_profile_edit_main_container_inner_section .personal_prof_form_2 input::placeholder,
    .user_profile_edit_main_container_inner_section .personal_prof_form_2 select::placeholder
    {
        font-size: 14px!important;
        padding: 8px 8px!important;
        border-radius: 0px!important;
    }

    .user_profile_edit_main_container_inner_section .personal_prof_form_2 label {
        font-size: 15px!important;
    }
    .user_create_profile_main_row button {
        margin: 26px 13px;
        padding: 10px;
        font-size: 15px;
        width: 94%;
        border-radius: 0px!important;
    }
}