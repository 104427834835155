a:focus, a:hover {
    color: inherit;
    text-decoration: none;
}
a:hover {
    color: #0a58ca;
}
a, button {
    color: inherit;
    outline: medium none;
    text-decoration: none;
}
a, .button {
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
ul {
    margin: 0px;
    padding: 0px;
}
.sticky {
    position: fixed;
    z-index: 999;
    width: 100%;
    background: #ffffff;
    top: 0;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-box-shadow: 0 0 10px 3px rgb(0 0 0 / 5%);
    box-shadow: 0 0 10px 3px rgb(0 0 0 / 5%);
}
li {
    list-style: none;
}
p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    font-family: "Manrope", sans-serif;
    color: #707070;
    margin-bottom: 0;
}
.theme_btn {
    overflow: hidden;
    color: #ffffff!important;
    background: #f7931e;
    font-size: 2.3vh;
    font-family: "Manrope", sans-serif;
    line-height: 1;
    padding: 18px 35px;
    display: inline-block;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.gradient-bg {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 114, 58, 0.05)), to(rgba(241, 77, 93, 0.03)));
    background: linear-gradient(rgba(255, 114, 58, 0.05) 0%, rgba(241, 77, 93, 0.03) 100%);
    background: -o-linear-gradient(rgba(255, 114, 58, 0.05) 0%, rgba(241, 77, 93, 0.03) 100%);
}
.pos-rel {
    position: relative;
}
.section-title h5 {
    color: #f7931e;
}
.bottom-line {
    position: relative;
    display: inline-block;
}
.section-title {
    position: relative;
    z-index: 2;
}
.bottom-line::before {
    content: '';
    position: absolute;
    background-color: #f7931e;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    z-index: 1;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Manrope", sans-serif;
    color: #1B212F;
    line-height: 1.1;
    margin-top: 0px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    text-transform: normal;
}

#scrollUp {
    background: #f7931e;
    width: 45px;
    height: 45px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 15px;
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
}
#scrollUp i {
    color: #ffffff;
}
.popup_header_style {
    border-bottom: 1px solid #ccc;
    padding: 12px;
    display: flex;
    position: relative;
}

.cancel_button {
    position: absolute;
    right: 17px;
    display: flex;
    width: 35px;
    height: 35px;
    font-weight: 600;
    background: #f7931e;
    border-radius: 100%;
    font-size: 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    top: 9px;
}

.cancel_button div {
    margin: auto;
}
.popup_heder_main_heading {
    width: 100%;
    text-align: center;
    font-size: 3.5vh;
    color: #383838;
    line-height: 30px;
}
.contact-form input {
    font-weight: 600;
}
.comments-form input:focus{
    border-color: #f7931e;
    outline: #f7931e;
}
button:focus, input:focus, input:focus, textarea, textarea:focus{
    outline: 0;
}
::selection {
    background: #000;
    color: #ffffff;
    text-shadow: none;
}
.theme_color{
    color:#f7931e;
}
.theme_bgcolor{
    background:#f7931e;
}
.error{
    color: red;
}
.comments-form input:focus .comments-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0;
}
.comments-form input::placeholder,.comments-form textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #525252;
    font-size: 17px;
    font-weight: 400;
}
.comments-form input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #525252;
    font-size: 17px;
    font-weight: 400;
}
.comments-form input::-ms-input-placeholder { /* Microsoft Edge */
    color: #525252;
    font-size: 17px;
    font-weight: 400;
}
.pos-rel {
    position: relative;
}
@keyframes loading-bar-spinner {
    0%   { transform: rotate(0deg);   transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}

div#loading-bar-spinner {
    position: fixed;
    z-index: 999999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    background: #ffffffd1;
}
.text_right{
    text-align: right;
}
div#loading-bar-spinner .spinner-inner {
    margin: auto;
    text-align: center;
    display: flex;
}

div#loading-bar-spinner .spinner-inner .spinner-icon-message {
    font-size: 23px;
    font-weight: 600;
    margin: auto;
    padding-left: 15px;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 70px;
    animation: loading-bar-spinner 400ms linear infinite;
    height: 70px;
    border: solid 8px transparent;
    border-top-color: #f7931e!important;
    border-left-color: #f7931e!important;
    border-radius: 50%;
    margin:auto;
}
@keyframes preloader {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}